import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TransferListModal from './TransferListModal';

import {
  Button,
  Checkbox,
  Select,
} from 'components';
import { EDIT_STATUS } from 'constants/index';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useClass } from 'store/class';
import { useSessions } from 'store/sessions';
import { useTeacher } from 'store/teacher';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  UiFilter,
  UiSearchInput,
} from './SessionFormTeacherModal.style';


const selectFilterTypeOptions = [
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  },
  {
    name: '帳號ID',
    value: 'targetUserId'
  }
];

const searchPlaceholderMap = {
  mobileNumber: '請輸入教師手機號碼',
  nickname: '請輸入教師暱稱',
  targetUserId: '請輸入教師帳號ID'
};

export const SessionFormTeacherModal = ({
  buttonName,
  editStatus,
  error,
  onChange,
  title,
  type,
}) => {
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;
  const [{ myClasses, classes }] = useClass();
  const { dataInfo: { id, isOwner } } = myClasses;
  const { dataInfo: { isOwner: orgISOwner } } = classes;
  const { classId ,sessionId } = useParams();
  const [{ organizationStaffs }, { getAllTeachers, getTeachers, getOrganizationStaffs }] = useTeacher();
  const [{ sessions }] = useSessions();
  const { dataMaps } = sessions;
  const [{
    currentGroupHostOnly,
    searchIsLoading,
    searchKey,
    searchTeacherValue,
    searchValue,
    teacherHelpText,
  }, setState] = useSetState({
    currentGroupHostOnly: false,
    searchIsLoading: false,
    searchKey: '',
    searchTeacherValue: '',
    searchValue: '',
    teacherHelpText: '請選擇教師',
  });

  const formatTeacherListData = (teacherList = []) => {
    // const { dataInfo: { isOwner, ownerId } } = myClasses;
    // const ownerIsTeacher = teacherList.some(item => item.id === ownerId);
    // if (isOwner && searchTeacherValue === '' && !ownerIsTeacher) {
    //   teacherList = [{
    //     id: profile.id,
    //     nickname: profile.nickname
    //   }, ...teacherList];
    // }
    return teacherList.map(item => ({
      id: item.id,
      imagePath: item.thumbnailUrl,
      title: item.nickname,
      content: item.mobileNumber,
    }));
  };

  const submitSelectHandler = value => {
    setState({
      searchKey: value
    });
  };
  const submitInputHandler = event => {
    setState({
      searchValue: event.target.value
    });
  };

  const submitSearchHandler = async () => {
    setState({
      searchIsLoading: true
    });
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }

    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };

    await getOrganizationStaffs(params);
    setState({
      searchIsLoading: false
    });
  };
  

  const onSwitchChange = (val) => {
    const currentGroupHostOnly = val === 'currentGroupHostOnly' ? true : false;
    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };
    getOrganizationStaffs(params);
    setState({
      currentGroupHostOnly: currentGroupHostOnly
    });
  };

  const fetchResourcesTeacher = async () => {
    if(!id && !classId) return;
    getAllTeachers();
    getOrganizationStaffs();

    // 畫面標題
    let title = '';
    switch (editStatus) {
      case EDIT_STATUS.CREATE:
      case EDIT_STATUS.COPY:
        title = '新增課程';
        break;
      case EDIT_STATUS.EDIT:
        title = '編輯課程';
        break;
      case EDIT_STATUS.READ:
        title = '檢視課程';
        break;
      default:
        break;
    }

    setState({ title });

    if (sessionId) {
      if (dataMaps) {
        // 取 store 資料
        let sessionInfo = dataMaps[sessionId];
        setState({ timeSpanId: sessionInfo.timeSpanId });
        sessionInfo = Object.assign(
          sessionInfo,
          { duration: sessionInfo.duration.slice(0, sessionInfo.duration.length - 2) }
        );
        setState({ data: sessionInfo });
      }
    }
  };

  // 取得所有教師、資源列表
  useEffect(() => {
    fetchResourcesTeacher();
  }, [id]);

  // 搜尋教師列表
  useEffect(() => {
    if (!searchTeacherValue) return;
    const params = {
      mobileNumber: searchTeacherValue
    };
    getTeachers(params);
  }, [searchTeacherValue]);

  return (
    <TransferListModal
      buttonName={buttonName}
      data={formatTeacherListData(organizationStaffs?.data)}
      editStatus={editStatus}
      error={error}
      errorMsg='無符合條件教師，請重新設定搜尋條件'
      helperText={teacherHelpText}
      onChange={onChange}
      title={title}
      type={type}
    >
      <UiFilter>
        <Select label="搜尋目標" options={selectFilterTypeOptions}
          value={searchKey} submitHandler={submitSelectHandler} />
        <UiSearchInput>
          <TextField placeholder={searchPlaceholderMap[searchKey]}
            defaultValue={searchValue}
            onChange={event => submitInputHandler(event)}
            fullWidth={true}
            type="search" variant="outlined" label='搜尋' InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}></TextField>
        </UiSearchInput>
        <Button onClick={submitSearchHandler} loading={searchIsLoading}>搜尋</Button>
        {
          isHome && (isOwner || orgISOwner) &&
            <Checkbox label="顯示本班教師" size='small' checkedValue="currentGroupHostOnly" onChange={onSwitchChange} />
          }
      </UiFilter>
    </TransferListModal>
  );
};

SessionFormTeacherModal.defaultProps = {
  buttonName: '選擇授課教師',
  onChange: ()=>{},
  title: '選擇授課教師',
  type: 'singleChoice',
};

SessionFormTeacherModal.propTypes = {
  buttonName: PropTypes.string,
  editStatus: PropTypes.string,
  error: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};