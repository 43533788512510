import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResourceModalContent from './ResourceModalContent';

import { Button, Icon, Modal } from 'components';
import { EDIT_STATUS } from 'constants/index';
import { getSessionResources as getSessionResourcesApi } from 'services/api/organization/session';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { getPrivateResources as getPrivateResourcesApi } from 'services/api/home/users';
import { useResource } from 'store/resource';
import {
  UiButtonContainer,
  UiCard,
  UiCards,
  UiCardRemove,
  UiCardSubTitle,
  UiCardTitle,
  UiMatFormHelperText,
  UiResourceModal,
  UiResourcesContainer,
} from './SessionFormResourceModal.style';

export const SessionFormResourceModal = ({
  buttonName,
  editStatus,
  error,
  hostId,
  onChange,
  title,
}) => {
  const { organizationId, sessionId } = useParams();
  const [, { getResourcesList, getBookstoreBooks }] = useResource();
  const [isCheckedCheckboxFull, setIsCheckedCheckboxFull] = useState(false); // 勾選的 checkbox 是否已滿
  const [isModalOpen, setIsModalOpen] = useState(false);  // 彈窗是否開啟
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResources, setIsLoadingResources] = useState(false);
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);       // 勾選的 checkbox 內容 {id, name, isChecked}
  const [checkedCheckboxPre, setCheckedCheckboxPre] = useState([]); // 前一次勾選的 checkbox 內容
  const [privateResources, setPrivateResources] = useState([]);   // 我的資源

  /* 關閉 modal */
  const getModalStateHandler = (state) => {
    setCheckedCheckbox(checkedCheckboxPre); // 把原本的勾選內容復原
    setIsModalOpen(state);
  };
  const clickCloseHandler = () => {
    setCheckedCheckbox(checkedCheckboxPre); // 把原本的勾選內容復原
    setIsModalOpen(false);
  };

  /* checkbox 取消勾選 */
  const changeUncheckCheckboxHandler = (resourceId) => {
    setCheckedCheckbox(checkedCheckbox.filter(el => el.id !== resourceId));
  };

  /* change checkbox 勾選狀況 */
  const changeCheckboxHandler = (e) => {
    let newCheckedCheckbox = [ ...checkedCheckbox ];
    const id = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;

    // 取消勾選
    if(newCheckedCheckbox.some(obj => obj.id === id)){
      changeUncheckCheckboxHandler(id);
    }
    // 勾選
    else{
      // 選項未滿
      if(checkedCheckbox.length < 6) {
        newCheckedCheckbox.push({ id, name, isChecked });
        setCheckedCheckbox(newCheckedCheckbox);
      }
      // 選項已滿
      else if(checkedCheckbox.length === 6){
        setIsCheckedCheckboxFull(true);
        setTimeout(() => 
          setIsCheckedCheckboxFull(false)
        ,[1500]);
      }
    }
  };

  /* click 確定，傳回至 SessionForm.js schema -> resourceIdList -> onChange() */
  const clickSubmitHandler = () => {
    // Array: checkedCheckbox.id
    const resourceIdList = checkedCheckbox.map(checkbox => {
      return checkbox.id;
    });
    setCheckedCheckboxPre(checkedCheckbox); // 把當前的勾選的內容設定至前一次狀態
    setIsModalOpen(false);
    onChange(resourceIdList); //傳回至 SessionForm.js
  };

  const clickCardRemoveHandler = (resourceId) => {
    const newCheckedCheckbox = checkedCheckbox.filter(el => el.id !== resourceId);
    const resourceIdList = newCheckedCheckbox.map(checkbox => {
      return checkbox.id;
    });
    setCheckedCheckboxPre(newCheckedCheckbox);
    setCheckedCheckbox(newCheckedCheckbox);
    onChange(resourceIdList);
  };

  /* 複製、編輯課程時，打API，取得教材資訊 */
  const fetchSessionResource = async () => {
    setIsLoading(true);
    const { isSuccess, data } = await getSessionResourcesApi(organizationId, sessionId);
    if(isSuccess){
      const newCheckedCheckbox = data.map(resource => {
        const id = resource.resourceId;
        const name = resource.displayName;
        const isChecked = true;
        return {
          id,
          name,
          isChecked
        };
      });
      setCheckedCheckbox(newCheckedCheckbox);
      setCheckedCheckboxPre(newCheckedCheckbox);
    }
    setIsLoading(false);
  };
  /* 打API，取得機構資源 */
  const fetchResourceOrganization = async (fileName = null) => {
    setIsLoadingResources(true);
    const params = { fileName };
    await getResourcesList(params);
    setIsLoadingResources(false);
  };
  /* 打API，取得公開資源 */
  const fetchResourcePublic = async (displayName = null, tags = null) => {
    setIsLoadingResources(true);
    const params = {
      nowPage: 0,
      rowsPage: 100,
      displayName,
      tags,
    };
    await getBookstoreBooks(params);
    setIsLoadingResources(false);
  };
  /* 打API，取得我的資源 */
  const fetchResourcePrivate = async () => {
    const params = {
      nowPage: 0,
      rowsPage: 100
    };
    const { data, isSuccess } = await getPrivateResourcesApi(params);
    if(isSuccess){
      const resourcesData = data.resources.map(resource=>{
        return {
          ...resource,
          id: resource.resourceId,
          title:resource.displayName,
        };
      });
      setPrivateResources(resourcesData);
      return resourcesData;
    }else{
      setPrivateResources([]);
      return [];
    }
  };
  /* 打API，取得所有資源 */
  const fetchData = async () => {
    setIsLoadingResources(true);
    await Promise.all([fetchResourceOrganization(), fetchResourcePublic(), fetchResourcePrivate()]);
    setIsLoadingResources(false);
  };
  useEffect(() => {
    // 複製、編輯課程時，取得課程之教材資源
    sessionId && (editStatus === EDIT_STATUS.COPY || editStatus === EDIT_STATUS.EDIT)
      && fetchSessionResource();
    // 把當前的勾選的內容設定至前一次狀態
    setCheckedCheckboxPre(checkedCheckbox);
    // 取得彈窗之資源
    fetchData();
  }, []);


  return (
    <>
      {/* 選擇教學檔案 - 彈窗 */}
      <Modal
        size='large'
        isIconShow={false}
        isOpen={isModalOpen}
        title={title}
        getModalState={getModalStateHandler}
        content={
          <ResourceModalContent
            checkedCheckbox={checkedCheckbox}
            editStatus={editStatus}
            fetchResourceOrganization={fetchResourceOrganization}
            fetchResourcePublic={fetchResourcePublic}
            hostId={hostId}
            isCheckedCheckboxFull={isCheckedCheckboxFull}
            isLoadingResources={isLoadingResources}
            onChangeCheckbox={changeCheckboxHandler}
            onChangeUncheckCheckbox={changeUncheckCheckboxHandler}
            onClickClose={clickCloseHandler}
            onClickSubmit={clickSubmitHandler}
            privateResources={privateResources} />
          }
        />

      <UiResourceModal>
        {/* 選擇教學檔案 - 按鈕 */}
        <UiButtonContainer>
          <Button
            loading={isLoading}
            onClick={()=>{setIsModalOpen(true);}}>
            {buttonName}
          </Button>
        </UiButtonContainer>

        {/* 選擇教學檔案 - 按鈕旁資源列表 */}
        <UiResourcesContainer>
          {
            (error && checkedCheckbox.length === 0) && (
              <UiMatFormHelperText >
                請選擇教學資源
              </UiMatFormHelperText>)
          }
          <UiCards>
            <TransitionGroup>
              {
                (checkedCheckbox === checkedCheckboxPre) && checkedCheckbox.map((checkbox, index) =>
                  <CSSTransition
                    key={checkbox.id}
                    classNames='card'
                    timeout={{
                      enter: (index + 1) * 350,
                      exit: 100,
                    }}
                  >
                    <UiCard index={index}>
                      <UiCardRemove>
                        <Icon
                          name='cancel'
                          haveBg={false}
                          icolor={({ theme }) => theme.palette.btn.normal}
                          onClick={()=>clickCardRemoveHandler(checkbox.id)}
                          size="1.5rem"
                        />
                      </UiCardRemove>
                      <UiCardTitle>{checkbox.name}</UiCardTitle>
                      <UiCardSubTitle>{checkbox.id}</UiCardSubTitle>
                    </UiCard>
                  </CSSTransition>
                )
              }
            </TransitionGroup>
          </UiCards>
        </UiResourcesContainer>
      </UiResourceModal>
    </>
  );
};

SessionFormResourceModal.defaultProps = {
  buttonName: '選擇教學檔案',
  title: '選擇教學檔案',
};

SessionFormResourceModal.propTypes = {
  buttonName: PropTypes.string,
  editStatus: PropTypes.string,
  error: PropTypes.string,
  hostId: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
};